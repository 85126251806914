/*eslint-disable */
import {bus} from '@/main';  
import uploadMedicalRecords from '@/components/private/health-record-main-popup';
import removeHealthRecords from '@/components/private/remove-modal-of-record';
import healthRecordViewPopup from '@/components/private/health-record-view-popup/';
import {
  mapGetters
} from 'vuex'; 
export default {
  name: 'health-records',
  components: {
    'upload-medical-record' : uploadMedicalRecords,
    'remove-health-record' : removeHealthRecords,
    'health-record-view' : healthRecordViewPopup
  },
  props: [],
  data () {
    return {
      uploadMedicalRecordsModal:false,
      healthRecordData:null,
      healthRecordDataResults:[],
      hRData:null,
      hRId:'',
      removeMedicalRecordsModal:false,
      healthRecordViewPopupState:false
    }
  },
  computed: {
    ...mapGetters([
      'healthRecordsGetters'
    ]),
    getHealthRecordListMethod() {
      return this.$store.getters.healthRecordsGetters;
    },
  },
  mounted () {
    bus.$on('uploadMedicalRecordsModalBus', (data) => {
      this.uploadMedicalRecordsModal = data
    });
    bus.$on('removeMedicalRecordsModalBus', (data) => {
      this.removeMedicalRecordsModal = data
    });
    bus.$on('healthRecordViewPopupStateBus', (data) => {
      this.healthRecordViewPopupState = data
    });
  },
  methods: {
    healthRecordViewPopupMethod(data){
      this.healthRecordViewPopupData = data
      this.healthRecordViewPopupState = true
      document.body.classList.add('popup_open_body')
    },
    removeHealthRecordModal(Id){
      this.hRId = Id
      document.body.classList.add('popup_open_body')
      this.removeMedicalRecordsModal = true
    },
    healthRecordModal(data){
      this.hRData = data
      document.body.classList.add('popup_open_body')
      this.uploadMedicalRecordsModal = true
    }
  },
  watch:{
    getHealthRecordListMethod(newVal, oldVal) {
      this.healthRecordData = newVal
      if(this.healthRecordData){
        this.healthRecordDataResults = this.healthRecordData.data
      }
    },
  }
}