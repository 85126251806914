/*eslint-disable */
import {bus} from '@/main'; 
export default {
  name: 'health-record-view-popup',
  components: {},
  props: ['healthRecordViewPopupData'],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    closeHealthRecordPopup() {
      bus.$emit('healthRecordViewPopupStateBus', false)
      document.body.classList.remove('popup_open_body')
    },
  }
}