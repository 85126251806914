/*eslint-disable */
import {bus} from '@/main';  
import toast from '@/services/toast';
export default {
  name: 'remove-modal-of-record',
  components: {},
  props: ['hRID'],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    closeRemoveRecord() {
      bus.$emit('removeMedicalRecordsModalBus', false)
      document.body.classList.remove('popup_open_body')
    },
    confirmRemoveRecord(){
      this.$store.dispatch('removeHelthRecordsDataByIdApiFun', {id:this.hRID}).then((res) => {
        toast.success(res.data.msg);
        this.getUpdateHealthRecordsListFun();
        this.closeRemoveRecord();
      }).catch((err) => {
        toast.error(err.data.user_msg || err.data.msg);
      })
    },
    getUpdateHealthRecordsListFun() {
      this.$store.dispatch('getHelthRecordsListApiFun')
    },
  }
}


